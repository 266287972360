/*******************************
         Site Overrides
*******************************/

  // Form error field
  .ui.form .field .prompt.label {
      border: none !important;
  }

  .ui.pointing.label:before, .ui[class*="pointing above"].label:before {
      display: none;
  }

  .ui.form .field.error input {
    background: inherit;
  }



