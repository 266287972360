/*******************************
         Site Overrides
*******************************/
tr.debit .amount {
  color: @green ;
}

tr.credit .amount {
  color: @red;
}

i.icon:not(.backIcon):not(.paginationIcon):not(.unreadicon):not(.approvalicon):not(.rejectedicon):not(.arrow.left):not(.approvedicon):not(.arrow.right){
  color: @checkGrey
}

i.icon.approvalicon {
  color: @primaryColor;
}

i.icon.rejectedicon {
  color: @red;
}

i.icon.approvedicon {
  color: @green;
}

td.purchased {
  color: @green;
}

td.sold {
  color: @red;
}
