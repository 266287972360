/*******************************
    User Variable Overrides
*******************************/
.ui.secondary.filters.menu .active.item, .ui.secondary.filters.menu .active.item:hover, .ui.borderless.pagination.secondary.pagefilter.menu .active.item, .ui.borderless.pagination.secondary.pagefilter.menu .active.item:hover {
  background: @primaryColor;
  color: @white;
} 

.ui.secondary.filters.menu .item, ui.borderless.pagination.secondary.pagefilter.menu .item, .ui.borderless.pagination.secondary.pagefilter.menu .icon.item  { 
  color: @primaryColor;
} 

.ui.menu.pagefilter .item {
  color: @primaryColor  ;
}