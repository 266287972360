/*******************************
         Container Overrides
*******************************/

.ui[class*="content-wrapper"].container {
    margin: 0 !important;
    background-color: @loading-content-background;
    width: 100% !important;
    min-height: 100%;
}

.ui[class*="dark content-wrapper"].container {
    background-color: @loginBackground;
}
