/*******************************
         Site Overrides
*******************************/

.ui.basic.primary.button.link-button {
    box-shadow: none !important;
}

.ui.primary.button {
    font-weight: 500;
}
